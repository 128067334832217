<template>
  <UiPopup
    :model-value="modelValue"
    :title="$t('archive_deal')"
    :description="isDealFinalized ? $t('archive_finalized_deal_description') : $t('archive_deal_description')"
    :primary-button-text="$t('archive_now')"
    :secondary-button-text="$t('keep_working')"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
  </UiPopup>
</template>

<script setup lang="ts">
import { useUiStore } from '@/store/ui'
import type { Deal } from '@/types'
import { CUSTOM_EVENTS, FINANCE_DEALS_STATUSES } from '~/constants'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  deal: Deal
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const isDealFinalized = props.deal.status.code === FINANCE_DEALS_STATUSES.CLOSED_FINALIZED
const loading = ref(false)

const submit = async () => {
  loading.value = true
  try {
    await useArchiveDeal(Number(props.deal.id))
    uiStore.showSnackBanner()
    document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_DEAL_DATA))
    emits('input')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
